@import "src/common/assets/styles/mixin";

@keyframes hotel-index-place-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.hotel-index-places {
  padding: 80px 0;
  position: relative;
  z-index: 2;

  &__header{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    align-items: center;
    margin-bottom: 48px;
    justify-content: space-between;

    @include mobileOnly() {
      grid-template-columns: auto;
      grid-gap: 8px;
      margin-bottom: 20px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 50px;


    @include mobileOnly() {
      font-size: 27px;
      font-weight: 400;
      line-height: 40px;
    }
  }

  &__banner{
    margin-top: 40px;
      text-align: center;
    img{
      //width: 100%;
      //max-width: 500px;
    }
  }

  &__image-list {
    overflow: hidden;
    height: 620px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    gap: var(--app-blocks-padding);

    a {
      img {
        //animation: hotel-index-place-animation 10s;
        //animation-play-state: running;
        //animation-iteration-count: infinite;
      }

      &:nth-child(1) {
        grid-area: 1 / 1 / 3 / 2;

        @include mobileOnly() {
          grid-area: 1 / 1 / 2 / 3;
        }
      }

      &:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;

        @include mobileOnly() {
          grid-area: 1 / 3 / 2 / 6;
        }

        img {
          animation-delay: 2s;
        }
      }

      &:nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;

        @include mobileOnly() {
          grid-area: 2 / 1 / 3 / 4;
        }

        img {
          animation-delay: 1s;
        }
      }

      &:nth-child(4) {
        grid-area: 1 / 3 / 3 / 4;

        @include mobileOnly() {
          grid-area: 2 / 4 / 3 / 6;
        }

        img {
          animation-delay: 4s;
        }
      }

      &:hover {
        transition: 0.2s all ease-in-out;
        transform: scale(0.99);
      }
    }

    @include mobileOnly() {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(2, 1fr);
      height: auto;
      gap: 8px;
    }
  }

  &__image-block {
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    img, picture {
      height: 100%;
    }

    @include mobileOnly() {
      height: 192px;
    }
  }

  &__image-text {
    position: absolute;
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 40px;
    color: #FFFFFF;
    text-shadow: 0 0 4px #000000;
    left: var(--app-blocks-padding);
    bottom: var(--app-blocks-padding);

    @include mobileOnly() {
      font-size: 15px;
      line-height: 22px;
      left: 10px;
      bottom: 10px;
    }
  }

  @include mobileOnly() {
    padding: var(--app-blocks-padding) 0;
  }
}


